<template>
  <van-dialog v-model="visible" title="提示">
    <div class="tip-box">
      <h3 class="number">当前巡检标识编号:</h3>
      <p>{{ currentCode }}</p>
    </div>
    <div class="tip-box">
      <h3 class="number">正确巡检标识编号:</h3>
      <div class="code-box">
        <span>总计查询到 {{ codeArr.length }} 个</span>
        <p v-for="(item, index) in codeArr" :key="index">
          <i>{{ index + 1 }}.</i>{{ item }}
        </p>
      </div>
    </div>
  </van-dialog>
</template>

<script>
import { getRecordRfidQrcode } from "@/api/psm/inspectionNew";

export default {
  name: "PopupInspectCodes",
  data() {
    return {
      visible: false,
      currentCode: "",
      codeArr: []
    };
  },
  methods: {
    async check({ recordId, type, code }) {
      let params = {
        recordId
      };
      let data = await getRecordRfidQrcode(params);
      this.currentCode = code;
      this.codeArr = data.filter(v => v[type]).map(v => v[type]);
      return this.codeArr.includes(code);
    }
  }
};
</script>

<style lang="scss" scoped>
.tip-box {
  margin: 0 20px;
  padding: 10px;
  background: #f7f7f7;
  border-bottom: 1px solid #eee;
  h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .code-box {
    min-height: 60px;
    max-height: 200px;
    overflow-y: scroll;

    span {
      display: inline-block;
      margin-bottom: 6px;
      font-size: 14px;
      color: #999;
    }
  }
  p {
    padding: 4px;
    color: #1676ff;
    i {
      display: inline-block;
      margin-right: 6px;
    }
  }
}
</style>
