<template>
  <div>
    <van-nav-bar
      title="检查内容"
      left-arrow
      :border="false"
      fixed
      @click-left="navLeftClick()"
    />
    <div class="content">
      <div>
        <van-collapse v-model="activeNames">
          <van-collapse-item
            v-for="(item, idx) of checkObjectData"
            :key="item.patrolObjectId"
            class="custom-collapse-item"
            :title="item.name"
            :name="idx"
            :border="false"
          >
            <template #title>
              <span class="title-index-box">{{ idx + 1 }}</span>
              <span class="title-text">{{ item.patrolObjectName }}</span>
              <div></div>
              <div v-if="statusDomTagSwitch" class="all-tag-box">
                <span v-if="item.statusFlag.includes('3')" class="loujian"
                  >漏检</span
                ><span v-if="item.statusFlag.includes('2')" class="abnormal"
                  >异常</span
                >
              </div>
              <div v-if="item.supplementFlag" class="all-tag-box">
                <span class="loujian">补录</span>
              </div>
              <van-dropdown-menu
                v-if="item.keepFlag"
                class="custom-dropdown-menu"
                active-color="#1676ff"
                @click.native.stop="void 0"
              >
                <van-dropdown-item
                  v-model="item.deviceStatus"
                  :title="deviceStatusTitleSwitch(item.deviceStatus)"
                  :options="deviceStatusOption"
                  title-class="custom-dropdown-title"
                  :disabled="!editing"
                  @change="val => deviceStatusChange(val, item)"
                />
              </van-dropdown-menu>
              <!-- <select v-model="deviceStatus" @click.stop="void 0">
                <option
                  v-for="opt of deviceStatusOption"
                  :key="opt.value"
                  :value="opt.value"
                >
                  {{ opt.text }}
                </option>
              </select> -->
            </template>
            <div class="interval"></div>
            <div v-if="item.deviceStatus === 1">
              <div
                v-for="(checkItem, checkItemIdx) of item.checkVOList"
                :key="checkItem.checkId"
                class="check-item-box"
              >
                <div class="title-box">
                  <div style="display: flex">
                    <span class="titleIcon"></span>
                    <span
                      :class="[
                        'title',
                        `title${idx}${checkItemIdx}`,
                        checkItem.openFlag ? 'title-ess' : ''
                      ]"
                      ><span>{{
                        `${checkItemIdx + 1}.${checkItem.name}`
                      }}</span>
                      <span v-if="!checkItem.flag" class="box1">
                        <span
                          v-show="checkItem.showFlag && !checkItem.openFlag"
                          class="ess-box"
                        ></span>
                        <span class="all-tag-box-new">
                          <span v-if="checkItem.verdict === 1" class="normal"
                            >正常</span
                          >
                          <span v-if="checkItem.verdict === 0" class="abnormal"
                            >异常</span
                          >
                        </span>
                        <span
                          v-if="checkItem.showFlag && !checkItem.openFlag"
                          class="open"
                          @click="openTitle(idx, checkItemIdx, 1)"
                          >展开</span
                        >
                        <span
                          v-if="checkItem.showFlag && checkItem.openFlag"
                          class="open"
                          @click="openTitle(idx, checkItemIdx, 2)"
                          >收起</span
                        >
                      </span>

                      <!-- v-if="checkItem.verdict === 1" -->
                    </span>

                    <!-- v-show="checkItem.showFlag" -->

                    <!--                    <img-->
                    <!--                      v-if="-->
                    <!--                        statusDomTagSwitch && statusTagSwitch(checkItem.verdict)-->
                    <!--                      "-->
                    <!--                      class="tag-img"-->
                    <!--                      :src="statusTagSwitch(checkItem.verdict)"-->
                    <!--                      width="37"-->
                    <!--                      height="16"-->
                    <!--                    />-->
                  </div>
                  <span v-if="checkItem.flag" class="all-tag-box">
                    <!-- v-if="
                          checkItem.verdict === 1 || checkItem.verdict === 0
                        " -->
                    <span v-if="checkItem.verdict === 1" class="normal"
                      >正常</span
                    >
                    <span v-if="checkItem.verdict === 0" class="abnormal"
                      >异常</span
                    >
                  </span>
                  <!-- <span
                    class="history"
                    @click="historyClick(checkItem.checkId)"
                  >
                    历史记录
                    <van-icon
                      name="iconfont-Line"
                      class-prefix="iconfont"
                      class="iconfont-Line"
                      size="5"
                    />
                  </span> -->
                </div>
                <div class="form-box">
                  <van-field
                    v-if="formModelTypeSwitch(2, checkItem.checkMethod)"
                    class="custom-van-field"
                    label-class="custom-label"
                    :label-width="labelWidth"
                    label="合格范围："
                  >
                    <template #input>
                      <span style="margin-right: 12px"
                        >上限：{{ checkItem.upBounds }}</span
                      >
                      <span>下限：{{ checkItem.downBounds }}</span>
                    </template>
                  </van-field>
                  <van-field
                    v-if="formModelTypeSwitch(1, checkItem.checkMethod)"
                    class="custom-van-field"
                    label-class="custom-label"
                    :label-width="labelWidth"
                    label="检查标准："
                  >
                    <template #input>
                      <span>{{ checkItem.checkStandard }}</span>
                    </template>
                  </van-field>
                  <van-field
                    class="custom-van-field"
                    label-class="custom-label"
                    :label-width="labelWidth"
                    label="检查方法："
                  >
                    <template #input>
                      <span>{{ checkItem.checkMethodName }}</span>
                    </template>
                  </van-field>
                  <template
                    v-if="formModelTypeSwitch(2, checkItem.checkMethod)"
                  >
                    <div v-if="inputDomSwitch" class="custom-van-field-wrapper">
                      <van-field
                        v-model="checkItem.actualResult"
                        class="custom-van-field input"
                        label-class="custom-label"
                        type="number"
                        label="测量值："
                        :label-width="labelWidth"
                        placeholder="请输入测量值"
                        :disabled="!editing"
                        :maxlength="10"
                        required
                        @input="onResultInput(checkItem)"
                      />
                      <span
                        v-if="hasCollectFunc"
                        class="btn-collect"
                        @click="onCollect(checkItem)"
                        >采集</span
                      >
                    </div>
                    <div v-else class="view-row">
                      <span class="label">测量值：</span>
                      <p class="text">{{ checkItem.actualResult }}</p>
                      <span v-if="checkItem.actualResult"
                        >（{{
                          getTaskCollectType(checkItem.collectType)
                        }}）</span
                      >
                    </div>
                  </template>
                  <template
                    v-if="formModelTypeSwitch(1, checkItem.checkMethod)"
                  >
                    <van-field
                      v-if="inputDomSwitch"
                      class="custom-van-field"
                      label-class="custom-label"
                      name="verdict"
                      label="是否正常："
                      :label-width="labelWidth"
                      required
                    >
                      <template #input>
                        <van-radio-group
                          v-model="checkItem.verdict"
                          direction="horizontal"
                          icon-size="16"
                          :disabled="!editing"
                        >
                          <van-radio :name="1">是</van-radio>
                          <van-radio :name="0">否</van-radio>
                        </van-radio-group>
                      </template>
                    </van-field>
                    <div v-else class="view-row">
                      <span class="label">是否正常：</span>
                      <p class="text">
                        {{ checkItem.verdictName }}
                      </p>
                    </div>

                    <van-field
                      v-if="inputDomSwitch"
                      v-model="checkItem.actualDesc"
                      class="custom-van-field input"
                      label-class="custom-label"
                      placeholder="请输入内容"
                      label="情况描述："
                      :label-width="labelWidth"
                      type="textarea"
                      maxlength="500"
                      show-word-limit
                      :autosize="{
                        minHeight: 42,
                        maxHeight: 42
                      }"
                      :disabled="!editing"
                      required
                    />
                    <div v-else class="view-row">
                      <span class="label">情况描述：</span>
                      <p class="text">{{ checkItem.actualDesc }}</p>
                    </div>
                  </template>
                  <van-field
                    v-if="inputDomSwitch"
                    v-model="checkItem.remark"
                    class="custom-van-field input"
                    label-class="custom-label"
                    placeholder="请输入内容"
                    label="备注："
                    :label-width="labelWidth"
                    type="textarea"
                    maxlength="500"
                    show-word-limit
                    :autosize="{
                      minHeight: 42,
                      maxHeight: 42
                    }"
                    :disabled="!editing"
                  />
                  <div v-else class="view-row">
                    <span class="label">备注：</span>
                    <p class="text">{{ checkItem.remark }}</p>
                  </div>
                  <p v-if="statusDomTagSwitch" class="view-row">现场照片：</p>
                  <div class="uploader-box">
                    <van-uploader
                      v-if="uploaderShow(checkItem.files)"
                      v-model="checkItem.files"
                      multiple
                      :max-count="6"
                      :max-size="1024 * 1024 * 10"
                      accept="image/*"
                      lazy-load
                      capture="camera"
                      :deletable="status === 1 && editing"
                      :show-upload="false"
                      upload-icon="plus"
                      upload-text="上传照片"
                      :after-read="afterRead"
                    />
                    <div
                      v-if="status === 1"
                      class="upload-emit-box"
                      @click="editing && cameraUpload(checkItem.files)"
                    >
                      <van-icon name="plus" size="24" />
                      <p>上传照片</p>
                    </div>
                  </div>
                  <template
                    v-if="
                      showTrouble &&
                        +checkItem.verdict === 0 &&
                        checkItem.verdict != null
                    "
                  >
                    <van-field
                      v-if="inputDomSwitch"
                      :rules="[{ required: true, message: '请填写隐患级别' }]"
                      :value="checkItem.troubleLevelName"
                      clickable
                      class="custom-van-field input"
                      label-class="custom-label"
                      label="隐患级别："
                      name="troubleLevel"
                      placeholder="请选择"
                      readonly
                      required
                      :disabled="!editing"
                      @click="
                        handlerTypesClick(
                          'troubleLevel',
                          checkItem,
                          checkItemIdx,
                          item.deviceStatus,
                          idx
                        )
                      "
                    />
                    <div v-else class="view-row">
                      <span class="label">隐患级别：</span>
                      <p class="text">{{ checkItem.troubleLevelName }}</p>
                    </div>
                    <van-field
                      v-if="inputDomSwitch"
                      :rules="[{ required: true, message: '请填写隐患类别' }]"
                      :value="checkItem.categoryName"
                      clickable
                      class="custom-van-field input"
                      label-class="custom-label"
                      label="隐患类别："
                      name="categoryId"
                      placeholder="请选择"
                      readonly
                      required
                      :disabled="!editing"
                      @click="
                        handlerTypesClick(
                          'categoryId',
                          checkItem,
                          checkItemIdx,
                          item.deviceStatus,
                          idx
                        )
                      "
                    />
                    <div v-else class="view-row">
                      <span class="label">隐患类别：</span>
                      <p class="text">{{ checkItem.categoryName }}</p>
                    </div>
                  </template>
                  <div class="historyBox">
                    <span
                      class="history"
                      @click="historyClick(checkItem.checkId)"
                    >
                      历史记录
                      <van-icon
                        name="iconfont-Line"
                        class-prefix="iconfont"
                        class="iconfont-Line"
                        size="5"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="check-item-box" style="padding-top: 0">
              <div class="form-box">
                <van-field
                  v-if="inputDomSwitch"
                  v-model="item.deviceDesc"
                  class="custom-van-field input"
                  label-class="custom-label"
                  placeholder="请输入内容"
                  label="现场情况描述："
                  label-width="100px"
                  type="textarea"
                  maxlength="500"
                  show-word-limit
                  :autosize="{
                    minHeight: 42,
                    maxHeight: 42
                  }"
                  :disabled="!editing"
                />
                <div v-else class="view-row">
                  <span class="label device">现场情况描述：</span>
                  <p class="text">{{ item.deviceDesc }}</p>
                </div>
                <p v-if="statusDomTagSwitch" class="view-row">现场照片：</p>
                <div class="uploader-box">
                  <van-uploader
                    v-if="uploaderShow(item.deviceFileList)"
                    v-model="item.deviceFileList"
                    multiple
                    :max-count="6"
                    :max-size="1024 * 1024 * 10"
                    accept="image/*"
                    lazy-load
                    capture="camera"
                    upload-icon="plus"
                    upload-text="上传照片"
                    :deletable="status === 1 && editing"
                    :show-upload="false"
                    :after-read="afterRead"
                  >
                  </van-uploader>
                  <div
                    v-if="status === 1"
                    class="upload-emit-box"
                    @click="editing && cameraUpload(item.deviceFileList)"
                  >
                    <van-icon name="plus" size="24" />
                    <p>上传照片</p>
                  </div>
                </div>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
    <div v-if="editing" class="bottom-fixed">
      <van-button class="btn" round type="info" @click="save()"
        >保存</van-button
      >
    </div>
    <!-- <punch-card
      v-if="
        status === 1 &&
          punchCardDomShow &&
          curPersonAuth &&
          supplementFlag === 0
      "
      :showNFCPopup="showNFCPopup"
      @click="punchCardClick()"
      @close="punchCardClose()"
    /> -->
    <SelectPopupGrade
      ref="selectPopupGrade"
      v-model="selectSensor"
      :immediately="false"
      list-key="value"
      title="蓝牙设备"
      :list="sensorList"
      :visible.sync="popupVisible"
      popup-height="50%"
      :filterable="false"
      output-type="string"
      @change="onSelectSensor"
    >
      <span slot="title" class="select-popup-taskproject">
        <span>蓝牙设备</span>
        <span
          class="refresh iconfont iconfont-shuaxin"
          @click="onRefreshSensor()"
        ></span>
      </span>
    </SelectPopupGrade>
    <timeout-reason-dialog
      :id="$route.params.recordId"
      v-model="visible"
      @close="visible = false"
      @success="onSuccess"
    />
    <InspectCodesTip ref="codeTip"></InspectCodesTip>
    <select-popup
      :choose-value="troublePopup.checked"
      :data="troublePopup.types"
      :title="troublePopup.title"
      :visible="troublePopup.visible"
      :popup-style="{
        height: '70vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { Toast } from "vant";
import axios from "@/utils/axios";
import {
  getTaskPointData,
  saveTaskPoint,
  NFCPunchCardSign,
  QRCodePunchCardSign,
  resetPunchCardByPointId,
  getIsLast,
  getInspectReportConf
} from "@/api/psm/inspectionNew";
import loujianTag from "../../assets/images/loujian-tag.png";
import yichangTag from "../../assets/images/yichang-tag.png";
import zhengchangTag from "../../assets/images/zhengchang-tag.png";
// import punchCard from "./components/punchCard";
import SelectPopupGrade from "@/components/SelectPopupGrade";
import { taskCollectTypeStr } from "@/utils";
import TimeoutReasonDialog from "@/views/pointCheckManagement/components/TimeoutReasonDialog";
import esign from "@/components/esign";
import InspectCodesTip from "@/views/inspectionNew/components/InspectCodesTip";
import { imageCompression } from "@/utils/file";
import Storage from "@/utils/storage";
const storage = new Storage();
import SelectPopup from "@/components/SelectPopup";
import getSelectMixin from "@/views/trouble/mixin/getSelectMixin";

let checkObjectData = null;

export default {
  name: "TaskProject",
  components: {
    SelectPopup,
    TimeoutReasonDialog,
    SelectPopupGrade,
    InspectCodesTip
  },
  data() {
    return {
      visible: false,
      hasCollectFunc: false,
      popupVisible: false,
      selectSensor: "",
      sensorList: [],
      searchSensorEnd: false,
      signType: this.$route.params.signType,
      activeNames: [0],
      // fileList: [],
      editing: false,
      checkObjectData: [],
      checkObjectDataCopy: [],
      statusDomTagWhiteList: [2, 3],
      inputDomWhiteList: [0, 1],
      formModelWhiteList: {
        1: [10, 40],
        2: [20, 30]
      },
      punchCardDomShow: false,
      showNFCPopup: false,
      deviceStatusOption: [
        {
          text: "运行",
          value: 1
        },
        {
          text: "停机",
          value: 2
        },
        {
          text: "维修",
          value: 3
        },
        {
          text: "备用",
          value: 4
        }
      ],
      labelWidth: "70px",
      executePersonId: "",
      supplementFlag: 0,
      endTime: "",
      troublePopup: {
        visible: false,
        checked: "",
        title: "",
        typeKey: "",
        typeNameKey: "",
        types: [],
        deviceStatus: "",
        index: "",
        parentIndex: ""
      },
      reportConf: {}
    };
  },
  mixins: [getSelectMixin],
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo,
      punchCardSuccess: state => state.inspectionNew.punchCardSuccess
    }),
    statusDomTagSwitch() {
      return this.statusDomTagWhiteList.includes(this.status);
    },
    inputDomSwitch() {
      return this.inputDomWhiteList.includes(this.status);
    },
    status() {
      return Number(this.$route.params.status);
    },
    curPersonAuth() {
      return !this.executePersonId || this.executePersonId === this.userInfo.id;
    },
    troubleLevelMap() {
      return (
        this.levelTypes?.reduce((prev, cur) => {
          prev[cur.value] = cur.label;
          return prev;
        }, {}) ?? {}
      );
    },
    troubleCategoryMap() {
      return (
        this.classesTypes?.reduce((prev, cur) => {
          prev[cur.value] = cur.label;
          return prev;
        }, {}) ?? {}
      );
    },
    showTrouble() {
      if (process.env.NODE_ENV === "development") return true;
      const taskTypeConf = this.reportConf.taskType?.split(",") ?? [];
      return (
        this.reportConf?.reportTroubleFlag &&
        taskTypeConf.some(x => this.taskType.includes(x))
      );
    },
    taskType() {
      return this.$route.query?.taskType?.split(",") ?? [];
    }
  },
  watch: {
    checkObjectDataCopy: {
      handler(val) {
        if (val.length > 0) {
          this.$nextTick(() => {
            val.forEach((item, index) => {
              item.checkVOList.forEach((val, idx) => {
                var obj = document.getElementsByClassName(
                  `title${index}${idx}`
                )[0];
                this.$set(
                  this.checkObjectData[index].checkVOList[idx],
                  "openFlag",
                  false
                );
                var newArr = val.name.match(/[0-9\.-]/g);
                if (
                  val?.name?.length - newArr?.length >= 34 &&
                  val?.name?.length - newArr?.length < 41
                ) {
                  // if (obj.children[0].offsetHeight > obj.offsetHeight) {
                  this.$set(
                    this.checkObjectData[index].checkVOList[idx],
                    "showFlag",
                    true
                  );
                  this.$set(
                    this.checkObjectData[index].checkVOList[idx],
                    "flag",
                    true
                  );
                } else if (val.name?.length - newArr?.length > 41) {
                  this.$set(
                    this.checkObjectData[index].checkVOList[idx],
                    "showFlag",
                    true
                  );
                  this.$set(
                    this.checkObjectData[index].checkVOList[idx],
                    "flag",
                    false
                  );
                } else {
                  this.$set(
                    this.checkObjectData[index].checkVOList[idx],
                    "showFlag",
                    false
                  );
                  this.$set(
                    this.checkObjectData[index].checkVOList[idx],
                    "flag",
                    false
                  );
                }
              });
            });
          });
        }
      },
      deep: true
    },
    checkObjectData: {
      handler(v) {
        console.log("v", v);
        // 保存到localStorage
        storage.setItem(
          this.localStorageKey,
          v,
          Date.now() + 1000 * 60 * 60 * 12 // 过期时间为12小时
        );
      },
      deep: true
    }
  },
  beforeRouteEnter(to, from, next) {
    checkObjectData = from.params.checkObjectData;
    next();
  },
  beforeRouteLeave(to, from, next) {
    checkObjectData = null;
    if (to.name !== "taskProjectHistory") {
      this.setPunchCardSuccess(false);
      this.punchCardClose();
    }
    next();
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  async created() {
    this.hasCollectFunc = !!window.androidH5.sersorRunning;
    this.type2Obj = {
      0: "m/s²",
      1: "mm/s",
      2: "mm"
    };
    this.indexObj = {
      "℃": 0,
      "℉": 0,
      "m/s²": 0,
      "mm/s": 1,
      mm: 2
    };
    this.getReportConf();
    this.editingTransform();
    await this.getTaskPointData();
    if (checkObjectData) {
      this.checkObjectData = checkObjectData;
    }
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    window.startSensorCollect = val => {
      const obj = JSON.parse(val);
      if (!this.currentCollectValue) {
        this.currentCollectValue = {
          temp: "",
          vibrate: {
            type2: "",
            v: "",
            hv: "",
            hv2: ""
          }
        };
      }
      if (obj.type == "temp") {
        // 如果是温度
        this.currentCollectValue.temp = obj.value;
      } else if (obj.type == "vibrate") {
        const [v, hv, hv2] = obj.value.split("~");
        this.currentCollectValue["vibrate"].type2 = this.type2Obj[obj.type2]; // 类型
        this.currentCollectValue["vibrate"].v = v; // 有效值
        this.currentCollectValue["vibrate"].hv = hv; // 峰值
        this.currentCollectValue["vibrate"].hv2 = hv2; // 峰峰值
      }
      this.canCollect = true;
    };
    window.stopSensor = () => {
      this.canCollect = false;
    };
  },
  beforeDestroy() {
    window.QRCodeCallback = null;
    window.onSearchSensorList = null;
    window.onSensorSearchEndCallback = null;
    window.onSersorRunning = null;
    window.startSensorCollect = () => {};
    window.stopSensor = null;
    //   this.setPunchCardSuccess(false);
    //   this.punchCardClose();
  },
  methods: {
    getReportConf() {
      const orgCode = this._orgCode;
      getInspectReportConf({ orgCode }).then(res => {
        this.reportConf = res[0] ?? {};
      });
    },
    handlerTypesClick(key, item, index, deviceStatus, parentIndex) {
      if (!this.editing) return;
      const map = {
        categoryId: {
          name: "categoryName",
          typesKey: "classesTypes",
          title: "隐患类别"
        },
        troubleLevel: {
          name: "troubleLevelName",
          typesKey: "levelTypes",
          title: "隐患级别"
        }
      };
      // 如果之前被选中了，那就直接传递过去
      this.troublePopup.checked = item[key] ?? "";
      this.troublePopup.typeKey = key;
      this.troublePopup.typeNameKey = map[key].name;
      this.troublePopup.types = this[map[key].typesKey] ?? [];
      this.troublePopup.title = map[key].title;
      this.troublePopup.index = index;
      this.troublePopup.parentIndex = parentIndex;
      this.troublePopup.deviceStatus = deviceStatus;
      this.troublePopup.visible = true;
      console.log(this.troublePopup, "this.troublePopup");
    },
    onPopupConfirm(row) {
      const { typeKey, typeNameKey, index, parentIndex } = this.troublePopup;
      const discoveryId = this.reportConf?.discoveryId ?? "";
      if (+this.troublePopup.deviceStatus === 1) {
        const item = this.checkObjectData[parentIndex].checkVOList[index];
        this.$set(item, typeKey, row.value);
        this.$set(item, typeNameKey, row.label);
        this.$set(item, "discoveryId", discoveryId);
        // item[typeKey] = row.value;
        // item[typeNameKey] = row.label;
      } else {
        const item = this.checkObjectData[index];
        this.$set(item, typeKey, row.value);
        this.$set(item, typeNameKey, row.label);
        this.$set(item, "discoveryId", discoveryId);
        // this.checkObjectData[index][typeKey] = row.value;
        // this.checkObjectData[index][typeNameKey] = row.label;
      }
    },
    onPopupClose() {
      this.troublePopup.visible = false;
      this.troublePopup.types = [];
      this.troublePopup.checked = "";
      this.troublePopup.title = "";
      this.troublePopup.typeKey = "";
      this.troublePopup.typeNameKey = "";
      this.troublePopup.deviceStatus = "";
      this.troublePopup.index = "";
      this.troublePopup.parentIndex = "";
    },
    openTitle(idx, checkItemIdx, type) {
      if (type === 1) {
        this.$set(
          this.checkObjectData[idx].checkVOList[checkItemIdx],
          "openFlag",
          true
        );
      } else {
        this.$set(
          this.checkObjectData[idx].checkVOList[checkItemIdx],
          "openFlag",
          false
        );
      }
    },
    ...mapMutations({
      setPunchCardSuccess: "setPunchCardSuccess"
    }),
    getTaskCollectType(v) {
      return taskCollectTypeStr(v);
    },
    // 如果手动输入过则说明是手动
    onResultInput(item) {
      item.collectType = 1;
    },
    // 选择传感器
    onSelectSensor(v) {
      // 开始采集
      window.androidH5.startSensor(
        "startSensorCollect",
        this.selectSensor,
        this.curCollectObj ? this.indexObj[this.curCollectObj.units] : 0
      );
    },
    onRefreshSensor() {
      this.searchSensorEnd = false;
      this.sensorList = [];
      window.androidH5.sersorRunning("onSersorRunning");
    },
    showLoading() {
      this.toast = Toast.loading({
        duration: 10000,
        message: "加载中..."
      });
    },
    hideLoading() {
      if (this.toast) {
        this.toast.clear();
      }
    },
    // 采集
    onCollect(item) {
      if (!window.onSearchSensorList) {
        window.onSearchSensorList = list => {
          const arr = list.split(",");
          const _list = arr.map(a => {
            const [name, mac] = a.split("~");
            return {
              label: name,
              value: mac
            };
          });
          this.sensorList = _list;
        };
      }
      // 是否搜索结束
      if (!window.onSensorSearchEndCallback) {
        window.onSensorSearchEndCallback = () => {
          this.hideLoading();
          this.searchSensorEnd = true;
        };
      }
      window.onSersorRunning = r => {
        // 如果没有连接上测温测振仪
        if (r == "0") {
          if (!this.popupVisible) {
            this.stopSensor("0");
          }
          this.popupVisible = true;
          this.curCollectObj = item;
          this.sensorList = [];
          if (this.searchSensorEnd && this.sensorList.length > 0) {
            return;
          }
          this.showLoading();
          window.androidH5.searchSendSensorList(
            "onSearchSensorList",
            "onSensorSearchEndCallback"
          );
        } else {
          if (!this.canCollect) {
            // 是否可以采集, 默认是不可以
            return;
          }
          // 判断上次和这次测量的数据是否是同一个类型的数据
          if (item.units == "℃" || item.units == "℉") {
            if (item.units == "℉") {
              item.actualResult = (
                +this.currentCollectValue["temp"] * 1.8 +
                32
              ).toFixed(2);
            } else {
              // 如果是温度
              item.actualResult = this.currentCollectValue["temp"];
            }
            // 设置为自动模式
            item.collectType = 2;
            return;
          } else if (item.units == "mm") {
            if (this.currentCollectValue["vibrate"].type2 == "mm") {
              // 位移, 峰峰值
              const hv2 = this.currentCollectValue["vibrate"].hv2;
              // 如果测温测振仪的型号不是ZoCo1000, 则需要换算成mm
              if (
                window.globalConfig.VUE_APP_VIBRATION_EQUIPMENT !== "ZoCo1000"
              ) {
                item.actualResult = +hv2 / 1000;
                item.actualResultOriginal = hv2;
              } else {
                item.actualResult = +hv2;
                item.actualResultOriginal = hv2;
              }
            } else {
              this.stopSensor();
            }
            item.collectType = 2;
          } else if (item.units == "mm/s") {
            if (this.currentCollectValue["vibrate"].type2 == "mm/s") {
              // 速度, 有效值
              item.actualResult = this.currentCollectValue["vibrate"].v;
            } else {
              this.stopSensor();
            }
            item.collectType = 2;
          } else if (item.units == "m/s²") {
            if (this.currentCollectValue["vibrate"].type2 == "m/s²") {
              // 加速度, 峰值
              item.actualResult = this.currentCollectValue["vibrate"].hv;
            } else {
              this.stopSensor();
            }
            item.collectType = 2;
          }
        }
      };
      // if (!window.onSersorRunning) {
      // }
      window.androidH5.sersorRunning("onSersorRunning");
    },
    // 停止测量
    stopSensor(showTip = "1") {
      window.androidH5.endSensor("stopSensor", showTip);
    },
    editingTransform() {
      if (this.$route.query.onlyLeakCheckStatus) {
        this.editing = true;
        return false;
      }
      if (this.status === 1) {
        if (+this.signType === 3 || this.punchCardSuccess) {
          this.editing = true;
        }
      }
    },
    navLeftClick() {
      if (!this.punchCardSuccess) {
        this.$router.back();
      }
      this.$dialog
        .confirm({
          className: "custom-confirm-dialog",
          title: "提醒",
          message: "您的数据尚未保存，是否确认返回？",
          messageAlign: "left",
          confirmButtonText: "是",
          confirmButtonColor: "#1676ff",
          cancelButtonText: "否",
          cancelButtonColor: "#6e6e7d"
        })
        .then(() => {
          resetPunchCardByPointId(this.$route.params.pointId);
          this.setPunchCardSuccess(false);
          this.$router.back();
        })
        .catch(() => {
          // on cancel
        });
    },
    async getTaskPointData() {
      let params = {
        patrolRecordId: this.$route.params.recordId,
        pointId: this.$route.params.pointId
      };
      if (this.$route.query.onlyLeakCheckStatus) {
        params.onlyLeakCheckStatus = this.$route.query.onlyLeakCheckStatus;
      }
      // 生成本地存储key
      this.localStorageKey = `${params.patrolRecordId}-${params.pointId}_local`;

      const data = await getTaskPointData(params);
      const activeNames = [];
      data.forEach((item, idx) => {
        activeNames.push(idx);
        if (!item.deviceStatus) item.deviceStatus = 1;
        if (!item.deviceFileList) item.deviceFileList = [];
        item.deviceFileList.forEach(dFile => {
          dFile.url = this.getFileUrl(dFile.id, false);
          dFile.isImage = true;
        });
        item.checkVOList.forEach(cItem => {
          if (!cItem.files) cItem.files = [];
          cItem.files.forEach(file => {
            file.url = this.getFileUrl(file.id, false);
            file.isImage = true;
          });
          cItem.troubleLevelName = this.troubleLevelMap[cItem.troubleLevel];
          cItem.categoryName = this.troubleCategoryMap[cItem.categoryId];
        });
      });
      this.checkObjectData = data;
      // 检查本地是否有数据, 如果有, 则使用本地的
      const localData = storage.getItem(this.localStorageKey);
      if (localData) {
        this.checkObjectData = localData;
        console.log("localData", localData);
      }
      this.checkObjectDataCopy = JSON.parse(
        JSON.stringify(this.checkObjectData)
      );
      this.activeNames = activeNames;
      const firstData = this.checkObjectData[0];
      if (firstData) {
        this.punchCardDomShow = !firstData.signTime;
        this.executePersonId = firstData.executePersonId || "";
        this.supplementFlag = firstData.supplementFlag || 0;
        this.endTime = firstData.endTime || "";
      }
      console.log(firstData, "firstData");
      this.editingTransform();
    },
    afterRead(file, formFiles) {
      file = Array.isArray(file) ? file : [file];
      file.map(item => {
        item.status = "uploading";
        item.message = "上传中...";
        delete item.content;
        // delete item.file;
        this.uploadFile(item)
          .then(res => {
            let res0 = res[0];
            item.status = "";
            item.message = "";

            item.url = this.getFileUrl(res0.id);
            item.name = res0.name;
            item.id = res0.id;
            item.size = res0.size;
            item.uploadTime = res0.uploadTime;
            // this.updateValue(formFiles);
            // this.updateVideoImage(item);
          })
          .catch(error => {
            item.status = "failed";
            item.message = "上传失败";
          });
      });
    },
    uploadFile({ file }) {
      const filePath = window.globalConfig.VUE_APP_FILE_URL + "/file/upload";
      const size = file.size / 1024;
      let pq = Number(window.globalConfig.VUE_APP_PICTURE_QUALITY);
      // pq为1则未设置/设置错误或设置为原图
      pq = isNaN(pq) ? 1 : pq || 1;
      if (size > 500 && pq !== 1) {
        return imageCompression(file, pq);
      }
      const fd = new FormData();
      fd.append("files", file);
      return axios.post(filePath, fd);
    },
    getFileUrl(id, downloadFlag = true) {
      return `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=${downloadFlag}&fileName=${id}`;
    },
    // updateValue(fileList) {
    //   console.log("updateValue---", fileList);
    //   if (!Array.isArray(fileList)) return;
    //   let value = [];
    //   if (fileList.length > 0) {
    //     value = fileList.map(item => {
    //       return {
    //         id: item.id,
    //         name: item.name,
    //         null: item.null,
    //         size: item.size,
    //         uploadTime: item.uploadTime,
    //         url: item.url
    //       };
    //     });
    //   }
    //   this.$emit("change", this.maxCount == 1 ? value[0] : value);
    // },
    statusTagSwitch(status) {
      const obj = {
        0: yichangTag,
        1: zhengchangTag,
        2: loujianTag
      };
      return obj[status];
    },
    uploaderShow(fileList) {
      const status = this.$route.params.status;
      if (status === 0) return false;
      if ([2, 3].includes(status) && (!fileList || !fileList.length))
        return false;
      return true;
    },
    historyClick(id) {
      this.$router.push({
        name: "taskProjectHistory",
        params: { id, checkObjectData: this.checkObjectData }
      });
    },
    formModelTypeSwitch(type, checkMethod) {
      return this.formModelWhiteList[type].includes(checkMethod);
    },
    async signAuth() {
      const signAuth =
        window.globalConfig.VUE_APP_INSPECT_SIGN === "true" || false;
      const { recordId, pointId } = this.$route.params;
      const isLast = (await getIsLast(recordId, pointId)) ?? false;
      return signAuth && isLast;
    },
    onSign() {
      return new Promise(esign.show);
    },
    async save() {
      const file = { signFileId: "", signFileName: "" };
      if (await this.signAuth()) {
        const f = await this.onSign();
        file.signFileId = f.id;
        file.signFileName = f.name;
      }
      const arr = this.checkObjectData.map(i => i.checkVOList);
      let outerIdx = 0;
      let innerIdx = 0;
      let findIdx = -1;
      const valArr = [20, 30];
      // const chooseArr = [10, 40];
      for (let i = 0; i < arr.length; i++) {
        outerIdx = i;
        const cur = arr[i];
        findIdx = cur.findIndex(ck => {
          innerIdx++;
          const key = valArr.includes(ck.checkMethod)
            ? "actualResult"
            : "verdict";
          return !ck[key] && ck[key] !== 0;
        });
        if (findIdx !== -1) {
          break;
        }
      }
      if (findIdx !== -1) {
        if (this._getIdGlobalModuleFeatureConfig("10200101001") === "0") {
          return this.$dialog
            .confirm({
              title: "提示",
              message:
                "部分检查项结果未填写，无法提交，点击确定定位到首个漏填检查项。"
            })
            .then(_ => {
              document.querySelector(".content").scroll({
                top: outerIdx * 75 + (innerIdx - 1) * 350,
                behavior: "smooth"
              });
            });
        }
        document.querySelector(".content").scroll({
          top: outerIdx * 75 + (innerIdx - 1) * 350,
          behavior: "smooth"
        });
        return this.$dialog
          .confirm({
            title: "提示",
            message: "部分检查项结果未填写，保存后不可重新填写，确认提交？"
          })
          .then(async () => {
            const { recordId, pointId } = this.$route.params;
            const params = {
              recordId,
              pointId,
              dto: this.checkObjectData,
              ...file
            };
            const res = await saveTaskPoint(null, null, params);
            console.log("出去了");
            // 保存后移除本地存储
            storage.removeItem(this.localStorageKey);
            if (res?.reportResults?.length) {
              Toast("未生成隐患，请至异常管理中处理");
            }
            if (res?.endStatus === 4) {
              this.visible = true;
            } else {
              this.$router.back();
            }
          });
      }
      const { recordId, pointId } = this.$route.params;
      const params = {
        recordId,
        pointId,
        dto: this.checkObjectData,
        ...file
      };
      const res = await saveTaskPoint(null, null, params);
      // 保存后移除本地存储
      storage.removeItem(this.localStorageKey);
      if (res?.reportResults?.length) {
        Toast("未生成隐患，请至异常管理中处理");
      }
      if (res?.endStatus === 4) {
        this.visible = true;
      } else {
        this.$router.back();
      }
    },
    onSuccess() {
      this.$router.back();
    },
    async punchCardClick() {
      // signType 1 nfc 2 二维码
      if (this.pageInfo.signType === 1) {
        this.nfcSign();
        return;
      }
      this.QRCodeSign();
    },
    punchCardClose() {
      this.showNFCPopup = false;
      window.NFCCallback = null;
    },
    nfcSign() {
      if (!window.android || !window.android.getNFCData) {
        this.$toast.fail("此版本不支持NFC检测");
        return;
      }
      this.showNFCPopup = true;
      window.NFCCallback = async rfid => {
        if (!this.showNFCPopup) return; // nfc页面关闭则不再处理
        const params = {
          executePersonId: this.userInfo.id,
          orgCode: this.userInfo.orgCode,
          recordId: this.$route.params.recordId,
          rfid
        };

        // 打卡失败提示，点位编码不在任务中
        let isValidate = await this.$refs.codeTip.check({
          recordId: params.recordId,
          type: "rfid",
          code: rfid
        });
        if (!isValidate) {
          this.$refs.codeTip.visible = true;
          return false;
        }

        const data = await NFCPunchCardSign(params);
        if (data?.isOverTime === 1) {
          return Toast("任务超期了");
        }
        const message = `<span>${data.pointName}</span><span style='float: right;'>${data.signTime}</span>`;
        this.$dialog
          .confirm({
            className: "custom-confirm-dialog",
            title: "打卡成功",
            message,
            messageAlign: "left",
            confirmButtonText: "确定",
            confirmButtonColor: "#1676ff",
            showCancelButton: false
          })
          .then(() => {
            this.setPunchCardSuccess(true);
            this.showNFCPopup = false;
            this.getTaskPointData();
            this.editing = true;
          })
          .catch(() => {
            // on cancel
          });
      };
      window.androidH5.getNFCData("NFCCallback", "0");
    },
    QRCodeSign() {
      if (!window.android || !window.android.scanQrCode) {
        this.$toast.fail("此版本不支持二维码打卡");
        return;
      }
      window.QRCodeCallback = async (QRCodeInfo, base64, filename) => {
        const dateNow = new Date().toJSON();
        const file = this.base64toFile(
          base64,
          filename || `二维码${dateNow}.jpg`
        );
        const uploadResult = await this.uploadFile({ file });
        const fileList = uploadResult || [];

        const params = {
          executePersonId: this.userInfo.id,
          orgCode: this.userInfo.orgCode,
          recordId: this.$route.params.recordId,
          code: JSON.parse(QRCodeInfo).data,
          fileList
        };

        // 打卡失败提示，点位编码不在任务中
        let isValidate = await this.$refs.codeTip.check({
          recordId: params.recordId,
          type: "qrcode",
          code: params.code
        });
        if (!isValidate) {
          this.$refs.codeTip.visible = true;
          return false;
        }

        const data = await QRCodePunchCardSign(params);
        if (data?.isOverTime === 1) {
          return Toast("任务超期了");
        }
        const message = `<span>${data.pointName}</span><span style='float: right;'>${data.signTime}</span>`;
        this.$dialog
          .confirm({
            className: "custom-confirm-dialog",
            title: "打卡成功",
            message,
            messageAlign: "left",
            confirmButtonText: "确定",
            confirmButtonColor: "#1676ff",
            showCancelButton: false
          })
          .then(() => {
            this.setPunchCardSuccess(true);
            this.getTaskPointData();
            this.editing = true;
          })
          .catch(() => {});
        window.QRCodeCallback = null;
      };
      window.androidH5.scanQrCode("QRCodeCallback", "1");
    },
    base64toFile(base64, filename) {
      let arr = base64.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    deviceStatusChange(val, deviceItem) {
      if (val === 1) {
        deviceItem.deviceDesc = "";
        deviceItem.deviceFileList = [];
        return;
      }
      deviceItem.checkVOList = deviceItem.checkVOList.map(item => {
        return {
          ...item,
          actualResult: "",
          actualResultOriginal: "",
          verdict: "",
          actualDesc: "",
          remark: "",
          files: []
        };
      });
    },
    deviceStatusTitleSwitch(deviceStatus) {
      const find = this.deviceStatusOption.find(
        item => item.value === deviceStatus
      );
      return find ? find.text : "状态";
    },
    cameraUpload(oriFileList) {
      // NOTE: 由相机拍照上传，不会再经过after-read事件
      if (!window.android || !window.android.takePhoto) {
        this.$toast.fail("此版本不支持相机拍照功能");
        return;
      }
      window.cameraCallback = async (base64, filename) => {
        const file = this.base64toFile(base64, filename || "打卡照片.jpg");
        const uploadResult = await this.uploadFile({ file });
        const fileList = uploadResult || [];
        oriFileList.push({
          ...fileList[0],
          url: this.getFileUrl(fileList[0].id, false),
          isImage: true
        });
        window.cameraCallback = null;
      };
      window.androidH5.takePhoto("cameraCallback", "0");
    }
  }
};
</script>

<style lang="scss">
.select-popup-taskproject {
  .refresh {
    margin-left: 10px;
  }
}
.custom-confirm-dialog {
  .van-dialog__message {
    font-size: 14px;
    font-weight: 400;
    color: #2e2e4d;
    line-height: 20px;
  }
  .van-dialog__footer {
    border-top: 1px solid #eaecf1;
  }
  .van-dialog__cancel {
    border-right: 1px solid #eaecf1;
  }
}
.custom-collapse-item {
  .van-collapse-item__title {
    padding: 12px 8px 12px 23px;
  }
  .van-collapse-item__content {
    padding: 0;
  }
  .van-cell__right-icon {
    margin-top: 5px;
  }
  .van-cell--clickable:active {
    background-color: #fff;
  }
  .custom-dropdown-menu {
    .van-dropdown-menu__bar {
      box-shadow: initial;
    }
    .custom-dropdown-title {
      font-size: 14px;
    }
  }
  .form-box {
    .custom-van-field {
      .custom-label {
        // width: auto;
        color: #8c8f97;
        margin: 0;
      }
      .van-field__control--custom {
        min-height: initial;
      }
    }
    .custom-van-field.input {
      .van-field__control {
        border: 1px solid #c7c9d0;
        padding: 0 6px 0;
        background-color: rgba(231, 241, 255, 0.3);
      }
      .van-field__word-limit {
        position: absolute;
        right: 2px;
        bottom: 0;
        font-size: 10px;
        color: #8c8f97;
      }
    }
    // .van-uploader {
    //   padding: 16px 0 0;
    // }
    .van-uploader__wrapper:first-child {
      div:nth-child(3n + 3) {
        margin: 0 0 12px 0;
      }
    }
    .van-uploader__wrapper {
      opacity: 1;
    }
    // .van-uploader__wrapper--disable {
    //   .van-uploader__upload {
    //     opacity: .7;
    //   }
    // }
    .van-uploader__upload,
    .van-uploader__preview {
      width: 92px;
      height: 72px;
      box-sizing: border-box;
      overflow: hidden;
      background: #f6f7fa;
      border: 1px solid #e1e3e8;
      border-radius: 5px;
      margin: 0 16px 12px 0;
    }
    .van-uploader__upload-icon {
      color: #b8bcc6;
      // font-size: 24px;
    }
    .van-uploader__upload-text {
      margin-top: 7px;
      color: #b8bcc6;
      // font-size: 12px;
    }
    .van-uploader__preview-image {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<style lang="scss" scoped>
.content {
  padding: 78px 16px 80px;
  height: calc(100vh - 98px);
  background-color: rgba(236, 238, 242, 0.5);
  overflow: auto;
  // .red {
  //   color: red;
  // }
  .custom-collapse-item {
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 8px 0px #dbdde4;
    margin: 0 0 20px;
    .interval {
      height: 20px;
      background: linear-gradient(180deg, #f5f7fa, rgba(247, 249, 252, 0));
    }
    .title-index-box {
      display: inline-block;
      font-size: 16px;
      color: #2e2e4d;
      width: 15px;
      height: 30px;
      line-height: 31px;
      background-color: #eaeff5;
      position: relative;
      text-align: center;
      margin: 0 15.5px 0 0;
    }
    .title-index-box:before {
      content: "";
      position: absolute;
      top: 0;
      left: -7.5px;
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-right: 7.5px solid #eaeff5;
    }
    .title-index-box:after {
      content: "";
      width: 0;
      height: 0;
      top: 0;
      right: -7.5px;
      position: absolute;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 7.5px solid #eaeff5;
    }
    .title-text {
      font-size: 14px;
      font-weight: 400;
      color: #2e2e4d;
      // border-bottom: 1px dotted #e1e3e8;
      // border-bottom: 1px dotted #e1e3e8;
      // border-bottom: 1px dotted #e1e3e8;
    }
    .all-tag-box + .all-tag-box {
      padding-left: 0px;
    }
    .all-tag-box {
      padding: 0 0 0 31px;
      display: inline-block;
      span {
        display: inline-block;
        border-radius: 2px;
        font-size: 10px;
        line-height: 14px;
        padding: 2px 6px;
        margin: 0 8px 0 0;
      }
      .loujian {
        color: #e97617;
        background: rgba(233, 118, 23, 0.1);
      }
      .abnormal {
        color: #f13030;
        background: rgba(241, 48, 48, 0.1);
      }
    }

    .custom-dropdown-menu {
      display: inline-block;
      .custom-dropdown-title {
        font-size: 14px;
      }
    }
    .check-item-box:not(:last-child) {
      border-bottom: 1px dashed #e1e3e8;
    }
    .check-item-box:not(:first-child) {
      padding: 23px 0 24px;
    }
    .check-item-box {
      padding: 0 0 24px;
      .title-box {
        // display: flex;
        justify-content: space-between;
        align-content: center;
        // height: 60px;
        line-height: 24px;
        padding: 0 6px 12px 0;

        .titleIcon {
          display: inline-block;
          width: 12px;
          height: 16px;
          background-color: rgba(22, 118, 255, 0.1);
        }
        .title {
          max-height: 48px;
          overflow: hidden;
          flex: 1;
          display: inline-block;
          color: #2e2e4d;
          padding: 0px 10px 0 4px;
          position: relative;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // -webkit-box-orient: vertical;
          // background-color: rgba(22, 118, 255, 0.1);
          .box1 {
            height: 24px;
            position: absolute;
            bottom: 0;
            right: 0px;
            display: flex;
            .open {
              color: #1676ff;
              align-self: flex-end;
              // position: absolute;
              // bottom: 0;
              // right: 10px;
              background: #fff;
            }
            .all-tag-box-new {
              background: #ffffff;
              // position: absolute;
              // bottom: 0;
              // right: 36px;
              // padding: 0 0 0 31px;
              span {
                display: inline-block;
                border-radius: 2px;
                font-size: 10px;
                line-height: 14px;
                padding: 2px 6px;
                margin: 0 8px 0 0;
              }
              .normal {
                color: #fff;
                background: #7db227;
              }
              .abnormal {
                color: #f13030;
                background: rgba(241, 48, 48, 0.1);
              }
            }
            .ess-box {
              display: inline-block;
              height: 24px;
              width: 24px;
              background: #fff;
              position: relative;
              // position: absolute;
              // right: 66px;
              // bottom: 0;
            }
            .ess-box::after {
              content: "...";
              font-weight: bold;
              position: absolute;
              bottom: 0;
              right: 0;
              padding: 0 20px 1px 45px;
              background: -webkit-linear-gradient(left, transparent, #fff 55%);
              background: -o-linear-gradient(right, transparent, #fff 55%);
              background: -moz-linear-gradient(right, transparent, #fff 55%);
              background: linear-gradient(to right, transparent, #fff 55%);
            }
          }
        }
        .title-ess {
          max-height: initial;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // -webkit-box-orient: vertical;
        }
        .tag-img {
          vertical-align: text-top;
          margin: 0 0 0 8px;
        }
      }
      .form-box {
        .custom-van-field-wrapper {
          display: flex;
          align-items: center;
          .btn-collect {
            width: 54px;
            height: 24px;
            display: inline-block;
            color: #fff;
            background-color: $--color-primary;
            vertical-align: middle;
            margin-left: 8px;
            text-align: center;
            border-radius: 12px;
            line-height: 24px;
            font-size: 12px;
          }
        }
        padding: 0 16px 0;
        .custom-van-field {
          flex: 1;
          font-size: 12px;
          padding: 4px 0;
          margin: 0;
          // height: 17.01px;
          line-height: 18px;
        }
        .custom-van-field.input {
          line-height: 24px;
        }
        .custom-van-field::after {
          border-bottom: 0;
        }
        .custom-van-field::before {
          left: -8px;
          top: 6px;
        }
        // .img-item {
        //   padding: 8px 0 0;
        //   .label {
        //     font-size: 12px;
        //     color: #8c8f97;
        //     padding: 0 0 4px;
        //   }
        //   .img-box:first-child {
        //     .img-wrapper:nth-child(3n + 3) {
        //       margin: 0 0 12px 0;
        //     }
        //   }
        //   .img-wrapper {
        //     display: inline-block;
        //     width: 92px;
        //     height: 72px;
        //     box-sizing: border-box;
        //     overflow: hidden;
        //     background: #f6f7fa;
        //     border: 1px solid #e1e3e8;
        //     border-radius: 5px;
        //     img {
        //       width: 100%;
        //       height: 100%;
        //     }
        //   }
        // }
        .view-row {
          display: flex;
          font-size: 12px;
          padding: 0;
          margin: 8px 0 0;
          line-height: 18px;
          .label {
            flex: none;
            width: 70px;
          }
          .label.device {
            width: 100px;
          }
          .text {
            word-break: break-all;
            color: #2e2e4d;
          }
        }
        .uploader-box {
          padding: 16px 0 0;
        }
        .upload-emit-box {
          display: inline-block;
          vertical-align: top;
          text-align: center;
          background: #f6f7fa;
          border: 1px solid #e1e3e8;
          border-radius: 5px;
          padding: 11.5px 21px;
          margin: 0 0 12px 0;
          // width: 92px;
          // height: 72px;
          box-sizing: border-box;
          overflow: hidden;
          font-size: 12px;
          font-weight: 400;
          color: #b8bcc6;
        }
        .historyBox {
          display: flex;
          justify-content: flex-end;
          .history {
            font-size: 12px;
            color: #1676ff;
          }
        }
      }
    }
  }
}
.bottom-fixed {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0 16px 16px;
  background-color: transparent;
  .btn {
    width: calc(100% - 32px);
    height: 44px;
    font-size: 18px;
    font-weight: 500;
  }
}
</style>
