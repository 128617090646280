class Storage {
  constructor() {
    if (!Storage.instance) {
      Storage.instance = this;
    }
    this.prefix = "ty_app_";
    this.mapInfo = {};
    return Storage.instance;
  }

  getKey(key) {
    this.mapInfo[key];
    return this.prefix + key;
  }

  getStorage(key) {
    if (key.endsWith("_local")) {
      return window.localStorage;
    }
    return window.sessionStorage;
  }

  setItem(key, v, expiredAt = 0) {
    if (!key) {
      return;
    }
    let r = {
      value: JSON.stringify(v),
      updateTime: Date.now(),
      expiredAt
    };
    this.getStorage(key).setItem(this.getKey(key), JSON.stringify(r));
  }

  getItem(key) {
    const r = this.getStorage(key).getItem(this.getKey(key));
    const rObj = JSON.parse(r);
    if (!rObj) {
      return "";
    }
    // 如果不存在过期时间, 或者没有过期
    if (!rObj.expiredAt || rObj.expiredAt >= Date.now()) {
      return JSON.parse(rObj.value);
    }
    // 如果过期, 移除该存储, 并直接返回空字符串
    this.removeItem(key);
    return "";
  }

  removeItem(key) {
    if (!key) {
      return;
    }
    if (key === "all") {
      this.removeAll();
      return;
    }
    this.getStorage(key).removeItem(this.getKey(key));
  }

  removeAll() {
    const keys = Object.keys(window.sessionStorage);
    keys
      .filter(a => a.startsWith(this.prefix))
      .forEach(k => {
        const key = k.replace(this.prefix, "");
        this.getStorage(key).removeItem(this.getKey(key));
      });
  }
}

export default Storage;
