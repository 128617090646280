<template>
  <van-dialog
    v-bind="$attrs"
    class-name="time-reason-dialog"
    :show-confirm-button="false"
    @close="onClose"
    @open="onOpen"
    v-on="$listeners"
  >
    <van-form ref="form" @submit="onSubmit">
      <van-field
        v-model="overtimeReason"
        rows="2"
        autosize
        name="overtimeReason"
        label="超时原因"
        type="textarea"
        maxlength="200"
        placeholder="请输入超时原因"
        :rules="[{ required: true, message: '请填写超时原因' }]"
      />
      <!--        show-word-limit-->
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </van-dialog>
</template>

<script>
import { fillInOvertimeReason } from "@/api/psm/inspectionNew";
import { operateMessage } from "@/utils";

export default {
  name: "TimeoutReasonDialog",
  props: ["id"],
  data() {
    return {
      overtimeReason: ""
    };
  },
  methods: {
    onClose() {
      this.overtimeReason = "";
      this.$refs.form.resetValidation();
    },
    onOpen() {},
    async onSubmit() {
      const r = await fillInOvertimeReason(this.id, this.overtimeReason);
      operateMessage(!!r, "操作");
      r && this.$emit("success");
    }
  }
};
</script>

<style lang="scss">
.time-reason-dialog {
  .van-dialog__content {
    padding-top: 20px;
  }
}
</style>
