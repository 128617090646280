import axios from "@/utils/axios";

import apiPath from "@/utils/apiPath";
const psm = apiPath.psm;
const inspection = apiPath.inspection;
const authorization = apiPath.authorization;
const equipment = apiPath.equipment;

// 巡检任务分页列表
export const getPatrolList = params => {
  // terminalFlag终端标识： 0-pc， 1-app
  params = { ...params, terminalFlag: 1 };
  return axios.get(`${inspection}/page/app/patrol-records`, { params });
};

// 巡检任务 web 分页列表
export const getInspectionPatrolList = params => {
  // terminalFlag终端标识： 0-pc， 1-app
  params = { ...params };
  return axios.get(`${inspection}/page/patrol-records`, { params });
};

// 巡检任务查询条件下拉数据，type=1 是任务类型， type=2 是任务状态
export const getTaskTypeSltData = type => {
  return axios.get(`${inspection}/patrol/record/select/${1}`);
};

// 巡检任务查询条件下拉数据，type=1 是任务类型， type=2 是任务状态
export const getTaskStatusSltData = type => {
  return axios.get(`${inspection}/patrol/record/select/${2}`);
};

/**
 * 获取巡检质量
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getInspectQulityList = () => {
  return axios.get(`${inspection}/select/records-check-status`);
};

// 巡检任务详情
export const getTaskInfoData = id => {
  return axios.get(`${inspection}/patrol/record/info/${id}`);
};

/**
 * 巡检任务详情巡检点
 * @param params
 */
export const getTaskPointInfoList = params => {
  return axios.get(`${inspection}/patrol-records/${params.id}/points`, {
    params
  });
};

// 开始巡检任务
export const startTask = (id, personIds, executePersonId) => {
  return axios.post(
    `${inspection}/patrol/record/start/${id}?partner=${personIds}&executePersonId=${executePersonId}`
  );
};

// 是否可以完成巡检任务
export const validTaskComplete = id => {
  return axios.post(`${inspection}/patrol/record/end/valid/${id}`);
};

// 是否可以完成巡检任务
export const completeTask = id => {
  return axios.post(`${inspection}/patrol/record/end/${id}`);
};

// 巡检点下的巡检对象
export const getTaskPointData = (params = {}) => {
  return axios.get(`${inspection}/patrol/record/list/object`, { params });
};

// nfc打卡
export const NFCPunchCardSign = params => {
  return axios.post(`${inspection}/patrol/record/sign`, params);
};

// 二维码打卡
export const QRCodePunchCardSign = params => {
  return axios.post(`${inspection}/patrol/record/sign/qrCode`, params);
};

// 获取巡检记录的 rfid 或者 qrcode 的 code list
export const getRecordRfidQrcode = params => {
  return axios.get(
    `${inspection}/patrol/record/${params.recordId}/rfid-qrcode`,
    { params }
  );
};

// 检查项历史记录
export const getTaskPointItemData = id => {
  return axios.get(`${inspection}/patrol/record/check/history/${id}`);
};

// 检查项表单保存
export const saveTaskPoint = (recordId, pointId, params) => {
  // if (pointId) {
  //   // 巡检
  //   return axios.post(
  //     `${psm}/patrol/record/check/add/${recordId}/${pointId}`,
  //     params
  //   );
  // }
  if (params.dto && params.dto.length) {
    params.dto.forEach(item => {
      if (item.checkVOList && item.checkVOList.length) {
        item.checkVOList.forEach(a => {
          if (a.units === "mm") {
            if (a.actualResult && !a.actualResultOriginal) {
              // 如果是瑞祥的
              if (
                window.globalConfig.VUE_APP_VIBRATION_EQUIPMENT !== "ZoCo1000"
              ) {
                a.actualResultOriginal = a.actualResult;
              } else {
                a.actualResultOriginal = +a.actualResult * 1000;
              }
            }
          }
        });
      }
    });
  }
  console.log("params", params);
  return axios.post(`${inspection}/patrol/record/check/add`, params);
};

// 重置检查相打卡状态
export const resetPunchCardByPointId = pointId => {
  return axios.post(`${inspection}/patrol/record/reset/${pointId}`);
};

// 开始补录
export const recordSupplement = params => {
  return axios.post(`${inspection}/patrol/record/supplement`, params);
};

// 开始补录
export const supplementEnd = params => {
  return axios.post(`${inspection}/patrol/record/supplement/end`, params);
};

// 上报位置数据
export const uploadGeo = params => {
  return axios.post(`${inspection}/patrol/gis/add`, params);
};

export const replenishInspectRecord = data => {
  return axios.post(`${inspection}/patrol/supplement/approve/create`, data);
};

/**
 * 申请补录
 * @param data
 * @returns {*}
 */
export const applySupplementById = data => {
  return axios.post(`${inspection}/apply/supplement/${data.id}`, data);
};

export const fillInOvertimeReason = (id, overtimeReason) => {
  return axios.post(`${inspection}/patrol/record/overtime-reason/${id}`, {
    overtimeReason
  });
};

export const getIsLast = (recordId, pointId) => {
  return axios.get(
    `${inspection}/patrol/record/check/latest/patrol-point/${recordId}`,
    { params: { pointId } }
  );
};

// 获取 - 巡检任务重没有打卡的巡检点(非顺序巡检)
export const getPointResultList = patrolRecordId => {
  return axios.get(
    `${inspection}/patrol/record/select/point-result-name/${patrolRecordId}`
  );
};

// 补偿打卡
export const onSignInvalid = params => {
  return axios.post(`${inspection}/patrol/record/sign-invalid`, params);
};

export const getInspectConfig = params => {
  return axios.get(`${inspection}/patrol/task/web/info/abnormal-config`, {
    params
  });
};

export const getNameSltData = params =>
  axios.get(inspection + "/select/patrol-tasks", { params });

export function getSelectExceptionPerson(params) {
  return axios.get(
    `${inspection}/patrol/check/abnormal/select/patrol-abnormal/execute-person`,
    { params }
  );
}

export const getPageInspectException = params =>
  axios.get(`${inspection}/patrol/check/abnormal/page`, { params });

export const getInfoInspectException = id =>
  axios.get(`${inspection}/patrol/check/abnormal/view/info/${id}`);

export const getSelectErrorClass = () =>
  axios.get(`${inspection}/patrol/check/abnormal/select`);

export const toTrouble = data =>
  axios.post(`${inspection}/patrol/check/abnormal/trouble/commit`, data);

export const toInner = data =>
  axios.post(`${inspection}/patrol/check/abnormal/create`, data);

export const toFault = data =>
  axios.post(`${inspection}/patrol/check/abnormal/fault/commit`, data);

export const getFaultInfoById = id =>
  axios.post(`${equipment}/info/device/faults/${id}`);

export const getTicketsInfoById = id =>
  axios.get(`${equipment}/info/tickets/${id}`);

export const getAbnormalInfo = id =>
  axios.get(`${inspection}/patrol/check/abnormal/info/${id}`);

export const getPersonList = params =>
  axios.get(`${authorization}/user/name/select`, { params });

export const getInspectReportConf = params => {
  return axios.get(`${inspection}/list/abnormal-deal-configs`, { params });
};
